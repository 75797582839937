@media screen and (max-width:655px) {

}

@media screen and (max-width: 850px)  and (min-width: 655px){

}

@media screen and (max-width: 1300px) and (min-width: 850px){


}

@media screen and (min-width: 850px){

}


@media screen and (max-width: 1350px) and (min-width: 900px) {

}


@media screen and (max-width: 900px){

}

.home-body{
    width:100%;
    min-height: 100vh;
    background-color: rgb(146,182,240);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-image: "";
}

.home-page-body{
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 80vh;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(146,182,240);
}

.part-page-body{
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 80vh;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(146,182,240);
}

.bottom-home-page-body{
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 50vh;
    justify-content: space-evenly;
    align-items: center;
}

.hero-title-body{
    /* border: white 1px solid; */
    height: 50vh;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-title{
    width: 80%;
    /* height: 80%; */
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    /* background-color: rgba(110, 161, 243,0.9); */
    background-color: rgba(222, 234, 252, 0.9);
    /* background-color: rgba(0, 0, 0,0.9); */
    /* box-shadow: 0px 0px 5px 5px rgba(110, 161, 243); */
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
        0px 7px 13px -3px rgba(0,0,0,0.3),
        0px -3px 0px 0px rgba(0,0,0,0.2) inset;

    border-radius: 10px;
}

.home-image{
    border: solid black 2px;
    /* background-color: black; */
    /* box-shadow: 0px 0px 30px 30px rgba(0, 0, 0); */
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
    0px 7px 13px -3px rgba(0,0,0,0.3),
    0px -3px 0px 0px rgba(0,0,0,0.2) inset,
    11px 15px 12px 0px rgba(0,0,0,0.22);
    aspect-ratio : 1 / 1;
    width: 80%;
}

.home-image img{
    width: 100%;
    height: 100%;
}

.hero-title-desription{
    width: 100%;
}

.hero-title-desription p{
    color: rgb(0, 0, 0);
    font-family: 'cute';
    /* text-align: right; */
    padding-left: 60px;
    font-weight: 700;
    font-size: 20px;
}

.hero-title-han{
    color: rgb(0, 0, 0);
    font-family: 'cute2';
    /* text-align: right; */
    font-size: 50px;
    margin-right: 30px;
}
  
/* .hero-title-slowlana{
    font-family: 'cute';
    margin-left: 10px;
    font-style: italic;
    color: #ffc93d;
    text-align: right;
    font-size: 50px;
} */

.hero-title-button-body{
    width: 100%;
    height:fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.hero-title-button{
    background-color: rgb(110, 161, 243);
    color: rgb(0, 0, 0);
    font-size: large;
    font-weight:bolder;
    border-radius: 5px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    border: solid black 2px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
    0px 7px 13px -3px rgba(0,0,0,0.3),
    0px -3px 0px 0px rgba(0,0,0,0.2) inset;
    font-family: 'cute';
    font-size: 20px;
}

.hero-title-button:hover{
    background-color: rgb(79, 139, 235);
    color: rgb(0, 0, 0);
    border: solid #020202 2px;
    transition: all 0.2s ease-in-out;
}

.hero-title-button2{
    background-color: rgb(228, 239, 255);
    color: rgb(0, 0, 0);
    font-size: large;
    font-weight:bolder;
    border-radius: 5px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    border: solid rgb(255, 255, 255) 2px;
    box-shadow: 0px 2px 4px 0px rgba(239, 254, 255, 0.4),
    0px 7px 13px -3px rgba(229, 249, 255, 0.3),
    0px -3px 0px 0px rgba(0, 0, 0, 0.2) inset;
    font-family: 'cute';
    font-size: 20px;
}

.hero-title-button2:hover{
    background-color: rgb(190, 213, 250);
    color: rgb(0, 0, 0);
    border: solid #ffffff 2px;
    transition: all 0.2s ease-in-out;
}




.explantion-body{
    /* border: whirgb(255, 255, 255)x solid; */
    height: 60vh;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.explantion-text-bottom{
    /* border: white 1px solid; */
    width: 80%;
    /* box-sizing: border-box; */
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: rgb(110, 161, 243);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
    0px 7px 13px -3px rgba(0,0,0,0.3),
    0px -3px 0px 0px rgba(0,0,0,0.2) inset;
}

.explantion-text-bottom h1{
    font-family: 'cute';
    color: white;
    text-align: center;
    font-size: 30px;
}


.explantion-text{
    /* border: white 1px solid; */
    width: 80%;
    /* height: 80%; */
    /* box-sizing: border-box; */
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: rgb(110, 161, 243);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4),
    0px 7px 13px -3px rgba(0,0,0,0.3),
    0px -3px 0px 0px rgba(0,0,0,0.2) inset;
}

.explantion-text h1{
    font-family: 'cute';
    color: white;
    text-align: center;
    font-size: 30px;
}

.explantion-text a{
    font-family: 'cute3';
    text-align: center;
    color: white;
    font-size: 20px;
    padding-top: 20px;
    font-weight: 700;
}

.explantion-text p{
    font-family: 'cute';
    color: white;
    font-size: 20px;
    padding-top: 20px;
    font-weight: 700;
}

.second-buy-button-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-inner-body{
    /* border: white 1px solid; */
    /* height: 60vh; */
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: black; */
    box-sizing: border-box;
    padding-top: 10px;
    /* background-color: rgba(0, 0, 0,0.9); */
    /* box-shadow: 0px 0px 30px 30px rgba(0, 0, 0); */
    border-radius: 10px;
}

.home-body-bottom{
    min-height: 120vh;
    background-color: black;
}

.how-it-works-section2{
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
}

/* .background-home-how-it-works{
    width:100%;
    min-height: 100vh;
    background-image: url("https://tempuspoker1.s3.eu-west-2.amazonaws.com/images/Background.jpeg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.how-it-works-section-text-holder{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    justify-items: center;
    margin-bottom: 20px;
    align-items: baseline;
}

.how-it-works-section-text-holder h1{
    font-family: 'cute';
    color: #ffc93d;
    font-style: italic;
    margin-right: 40px;
    white-space: nowrap;
    font-size: 32px;
    
}

.how-it-works-section-text-holder p{
    font-family: 'Roboto Condensed', sans-serif;
    color: white;
    margin-right: 40px;
    font-size: 18px;
    font-weight: 700;   
}

.how-it-works-section2 h1{
    font-family: 'cute';
    color: #ffc93d;
    font-size: 20px;
    font-weight: 700;  
}

.how-it-works-section-text-holder2{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    justify-items: center;
    margin-bottom: 30px;
    align-items: baseline;
    margin-top: 50px;
}

.how-it-works-section-text-holder2 h1{
    font-family: 'cute';
    color: #ffc93d;
    font-style: italic;
    margin-right: 40px;
    white-space: nowrap;
    font-size: 32px;
    text-align: center;
    
}

.how-it-works-section-text-holder2 p{
    font-family: 'Roboto Condensed', sans-serif;
    color: white;
    margin-right: 40px;
    font-size: 15px;
    font-weight: 700;   
}


.title-how-it-works h1{
    color: white;
    margin-bottom: 40px;
    font-size: 30px;
}

.title-how-it-works a{
    font-family: 'cute';
    color: #ffc93d;
    font-size: 30px;
    font-weight: 700;  
}

.social-row{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.social-container{
    width: 75px;
    height: 75px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.social-container-tiktok{
    width: 75px;
    height: 75px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    border: white 1px solid;
}

.social-img{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 900px){
    .hero-title-button-body{
        padding-top: unset;
    }

    .home-body{
        min-height: unset;
    }

    .home-page-body{
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        padding-bottom: 50px;
    }

    .part-page-body{
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        padding-bottom: 50px;
    }

    .explantion-body{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .explantion-text-bottom{
        width: 70%;
    }

    .social-container{
        width: 50px;
        height: 50px;
    }

    .home-inner-body{
        width: 70%;
        padding-top: 30px;
    }

    .home-image{
        /* box-shadow: unset;
        background-color: unset; */
        border: unset;
    }

    .hero-title-body{
        width: 90%;
        height: unset;
        margin-top: 50px;
    }

    .hero-title-han{
        text-align: left;
        font-size: 20px;
        font-weight: 1000;
    }

    .hero-title-slowlana{
        font-size: 20px;
        text-align: left;
    }

    .hero-title-desription p{
        padding-left: 0;
        text-align: left;
    }

    .explantion-body{
        width: 100%;
        height: unset;
    }

    .how-it-works-section2{
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .title-how-it-works h1{
        font-size: 30px;
    }

    .how-it-works-section-text-holder{
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }

    .how-it-works-section-text-holder p{
        font-size: 18px;
    }

    .how-it-works-section2{
        padding-top: 0px;
    }

    .how-it-works-section2 p{
        text-align: center;
        margin-right: 0;
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
    }

    .how-it-works-section2 h1{
        font-size: 25px;
        margin-right: 0;
        text-align: center;
    }

    .how-it-works-section2 a{
        font-size: 25px;
        margin-right: 0;
        text-align: center;
    }
}

@media screen and (min-width: 900px) and (max-height:800px){

    .hero-title-button-body{
        padding-top: 10px;
    }

    .hero-title-desription p{
        font-size: 15px;
    }

    .hero-title-han{
        font-size: 40px;
    }

    .hero-title-slowlana{
        font-size: 40px;
    }
}

@media screen and (min-width: 900px) and (max-height:750px){
    .home-page-body{
        /* margin-bottom: 200px; */
    }

    .part-page-body{
        padding-top: 200px;
    }

    .bottom-home-page-body{
        margin-top: 100px;
    }
}

/* @media screen and (min-width: 900px) and (min-height:750) and (max-height:850px){


    .hero-title-button-body{
        padding-top: 15px;
    }

    .hero-title-desription p{
        font-size: 17px;
    }

    .hero-title-han{
        font-size: 45px;
    }

    .hero-title-slowlana{
        font-size: 45px;
    }
} */