.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background{
  width:100%;
  height: 100vh;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgb(0, 0, 0, 0.1) 90%);
  background-color: black;
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track{
  color: rgb(255, 0, 0);
  background-color: #000000;
}

::-webkit-scrollbar-thumb{
  background-image: linear-gradient(rgb(0,0,0),rgb(110, 161, 243),rgb(0, 0, 0));
  border-radius: 25px;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

@font-face {
  font-family: 'cute3';
  src: local("cute3"),
  url("./components/fonts/cute3.ttf") format("truetype");
}

@font-face {
  font-family: 'cute2';
  src: local("cute2"),
  url("./components/fonts/cute2.ttf") format("truetype");
}

@font-face {
  font-family: 'cute';
  src: local("cute"),
  url("./components/fonts/cute.ttf") format("truetype");
}