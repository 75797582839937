
nav {
  /* display: flex; */
  justify-content: space-between;
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 4 !important;
  font-size: 1.2rem;
  flex-wrap: nowrap !important;
  background-image: linear-gradient(rgba(222, 234, 252, 0.9),rgba(0,0,0,0))
}


.menu-holder{
  position: relative;
  right: 0;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
  /* margin-right: 20px; */
  padding-top: 2px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
  /* background-image: url(<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>); */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 352 512'%3E%3Cpath fill='white' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z/%3E%3C/svg%3E");
}

.bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
  color: "white";
}

.mobile-nav-container{
  display: flex;
}

.logo {
  /* height: 60px; */
  width: 100%;
  color: rgb(0, 0, 0);
  /* font-family: "poker"; */
  font-family: 'Roboto Condensed', sans-serif;
  padding-left: 2em;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 1000;
}

.logo img{
  height: 100%;
  width: auto;
  padding-bottom: 5px;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  margin-top: 15px;
  text-shadow: none;
  z-index: 5;
  opacity: 0.9 !important;
}

.menu-list.close{
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

/* .menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  background-color: #cd0101;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 10px;
} */

.menu-list li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 100;
  padding: 5px 10px;
  margin-right: 20px;
  /* font-family: "poker"; */
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-size: 20px;
  border-radius: 10px;
  font-family: 'cute2';
}

.menu-list li a:hover,
.menu-list li a.active {
  text-decoration: underline #ffc93d;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  opacity: 1 !important;
  color: #ffc93d;
}

.socials-button{
  width: 80px;
  height: 70px;
  cursor: pointer;
  background-color:  rgba(0, 0, 0, 0);
  border: none;
}

.socials-button img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.socials-nav{
  text-decoration: none;
  color: #000000;
  font-weight: 1000;
  padding: 5px 10px;
  margin-right: 20px;
  font-family: 'cute2';
  /* font-family: 'Roboto Condensed', sans-serif; */
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
}

.socials-nav:hover,
.socials-nav.active {
  text-decoration: underline #000000;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  opacity: 1 !important;
  color: #000000;
}

.han{
  color: rgb(0, 0, 0);
  font-family: 'cute2';
}

.slowlana{
  font-family: 'cute2';
  margin-left: 10px;
}

@media screen and (max-width: 900px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 20px;
    margin-left: 10px;
  }
  .menu-list{
    
  }
  .nav{
    z-index: 5 !important;
    /* background: rgba(255, 255, 255, 0.85); */
    background: rgba(0, 0, 0, 0.85);
  }

  .han{
    color: white;
  }
  .slowlana{
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .menu-icon {
    display: block;
    margin-top: 5px;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    /* background: rgba(255, 255, 255, 0.95); */
    background: rgba(0, 0, 0, 0.95);
    /* padding: 20px; */
    right: 0;
    /* top: 49px; */
    top: 23px;
    transition: all 0.5s ease-in-out;
    border-bottom: #505050 solid;
  }

  .socials-nav{
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: unset;
    margin-right: unset;
    color: white;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 100px;
    display: block;
    margin: auto;
  }

  .menu-list.close {
    top: -760%;
    transition: all 0.5s ease-in-out;
  }
}
